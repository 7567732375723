<template>
  <div class="app-mall-list container">
    <div class="content filters h c">
      <form-info-item label-position="right" label="品牌：" content-style="padding: 0;" style="margin-right: 20px;">
        <quick-select v-model="filters.brandId" filterable clearable url="api/brand" placeholder="请选择品牌" style="width: 180px;" @change="filters.seriesId = null; toQuery();" />
      </form-info-item>
      <form-info-item label-position="right" label="系列：" content-style="padding: 0;" v-if="filters.brandId" style="margin-right: 20px;">
        <quick-cascader v-model="filters.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="请选择系列" expand-trigger="hover" style="width: 180px;" />
      </form-info-item>
      <form-info-item label-position="right" label="分类：" content-style="padding: 0;" style="margin-right: 20px;">
        <quick-cascader v-model="filters.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="请选择分类" expand-trigger="hover" style="width: 200px;" />
      </form-info-item>
    </div>
    <div class="content v padding-20-0" v-loading="loading">
      <template v-if="data && data.length">
        <div class="h wrap">
          <goods-item v-for="g in data" :key="g.id" :shop-id="shopId" :goods="g" size="200px" />
        </div>
        <el-pagination class="sa-c" :total="total" :page-size="size" :current-page="page + 1" style="margin: 20px 0;" layout="total, prev, pager, next" @size-change="sizeChange" @current-change="pageChange" />
      </template>
      <div class="fc-g ta-c" v-else>没有找到符合要求的商品哦</div>
    </div>
  </div>
</template>

<script>
import GoodsItem from "../assembly/goods-item";
import initData from "@/mixins/initData";
import request from "@/utils/request";

export default {
  components: { GoodsItem },
  mixins: [initData],
  props: {
    shopId: Number | String,
  },
  data() {
    return {
      size: 24,
      filters: {
        brandId: null,
        seriesId: null,
        categroyId: null,
        keywords: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/shop/spu";
      this.data = [];
      if (this.$route.query.kw) {
        this.filters.keywords = decodeURIComponent(this.$route.query.kw);
      } else {
        this.filters.keywords = null;
      }
      Object.assign(this.params, this.filters);
      return true;
    },
  },
  mounted() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
};
</script>